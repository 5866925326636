<template>
<div :class="'tgju-widgets-block tgju-widgets-block-post ' + col ">
    <!-- فرم ارسال توییت -->
    <PostSend v-if="!this.$route.params.username && user" :newPost="newPost" ref="post-postSend"></PostSend>
    <div v-if="widgetLoad" class="tgju-widget light post mb-3">
        <div class="text-center p-2">
            <div class="widget-loading"></div>
        </div>
    </div>
    <!-- پست -->
    <template v-else-if="posts.data.length">
        <div class="tgju-widget light post mb-3" v-for="post in posts.data" :key="post.id">
            <div class="post-row">
                <PostItem :post="post" :group_id="group_id"></PostItem>
            </div>
        </div>
        <div class="tgju-widget light border-0  post mb-3">
            <div v-if="posts.last_page > 1" class="p-3 d-flex justify-content-center pagination-box-items">
                <Paginate :page-count="posts.last_page" :click-handler="loadData" :prev-text="'قبل'" :next-text="'بعد'" :container-class="'pagination'"></Paginate>
            </div>
        </div>
    </template>
    <template v-else>
        <div class="tgju-widget light post mb-3">
            <div class="text-center p-2">
                <div class="not-found-items">
                    <i class="uil uil-comment-alt-slash"></i>
                    <div class="no-content">درحال حاضر هیچ پستی برای نمایش وجود ندارد</div>
                </div>
            </div>
        </div>
    </template>
    <!-- </div>-->
</div>
</template>

<style lang="scss">
.textarea-post {
    padding: 10px;

    &:focus {
        outline: 0;
    }

    .emoji {
        width: 20px;
    }
}

.post .no-content {
    padding: 20px 15px;
    font-size: 13px;
}

#popper-button {
    position: relative;
    top: -4px;
}

#btn-emoji-default {
    margin: 0 !important;
    padding: 0 !important;
    width: 30px !important;
    height: 22px !important;
}

#btn-emoji-default>div>img.emoji {
    height: 20px !important;
    width: 20px !important;
}

#emoji-container>#emoji-popup img.emoji {
    height: 21px !important;
    width: 21px !important;
}

#emoji-container>#emoji-popup #emoji-popover-search>#search-header>span {
    padding: 10px 10px !important;
}

#emoji-container>#emoji-popup #emoji-popover-search>#search-header>input {
    font-size: 14px !important;
}

#popper-container {
    z-index: 9999 !important;
}

#popper-container #arrow {
    display: none !important;
}

#emoji-container>#emoji-popup .emoji-popover-inner {
    margin-right: -4px !important;
}

#emoji-container>#emoji-popup .emoji-popover-inner>div>.emoji-list>span {
    font-size: 8px !important;
}

.textarea-post .emoji {
    margin-right: 4px;
}

.profile-post-content .emoji {
    width: 17px;
    margin-right: 3px;
}

.profile-post-content .post-img {
    max-width: 100%;
    margin-top: 20px;
}

.left-menu-dropdown {
    display: block !important;
}

.user-block-reshare {
    margin: 20px;
    display: block;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.03) !important;
    border-radius: 6px !important;
    border: 1px solid #dee2e6;

    .tgju-widget-content {
        padding: 6.4px 20px 10px;
    }

    .user-block-avatar {
        width: 35px;
        height: 35px;
    }

    .post-btn-row {
        display: none;
    }

    .reshare-label {
        text-align: center;
        color: #676767;
        white-space: nowrap;
        display: flex;
        align-items: center;
        padding: 9px 15px;
        justify-content: start;
        border-bottom: 1px solid #ccc;

        i {
            font-size: 18px;
            line-height: 0;
            position: relative;
            margin-left: 5px;
            top: 1px;
        }

        .text {
            font-size: 11px;
        }
    }
}

.emoji-mart {
    z-index: 99999999;
    position: absolute;
    left: 45px;
}

.vue-popover-theme {
    z-index: 999;

    .tooltip-inner {
        border-radius: 5px;
        padding: 6px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 12px;
        background: rgb(249, 249, 249);
    }

    .mention-item {
        padding: 3px 7px;
        cursor: pointer;
        border-radius: 5px;

        &.mention-selected {
            background: rgb(0, 123, 255);
            color: #fff;
        }
    }
}
</style>

<script>
import PostSend from '@/components/Posts/PostSend.vue'
import PostItem from '@/components/Posts/PostItem.vue'
import Paginate from "vuejs-paginate";

export default {
    name: 'Post',
    components: {
        PostSend,
        PostItem,
        Paginate,
    },
    props: ['group_id', 'username', 'col'],
    data: function () {
        return {
            newPost: {
                content: '',
                type: 'post',
                parent_id: 0,
                group_id: this.group_id,
                attachments: [],
            },
            posts: {
                length: undefined,
                current_page: undefined,
                from: undefined,
                last_page: undefined,
                next_page_url: undefined,
                per_page: undefined,
                prev_page_url: undefined,
                to: undefined,
                total: undefined,
                data: {
                    length: undefined,
                }
            },
            widgetLoad: true,
            user: this.$helpers.userSync(),
        }
    },
    mounted() {
        this.loadData();
        this.$root.$on('reshare-post-then-update-twit', (data) => {
            this.$root.$off('reshare-post-then-update-twit');
            this.posts.data.unshift(data);
        });
    },
    methods: {
        loadData(page) {
            let api_data = {
                group_id: this.group_id,
                username: this.username,
                page: page ? page : 1,
            };

            this.$helpers.makeRequest('GET', '/post/list', api_data).then(api_response => {
                this.$helpers.api_alert(api_response);

                if (api_response.status == 200) {
                    let response = api_response.data.response;
                    this.posts = response.posts;
                    this.widgetLoad = false;
                }
            });
        },
        setCaretToEnd() {
            let postSendComponent = this.$refs['post-postSend'];
            postSendComponent.setCaretToEnd(postSendComponent.$refs['textarea-post']);
        },
        scrollTo(container) {
            let postSendComponent = this.$refs['post-postSend'];
            postSendComponent.scrollTo(container);
        },
    },
}
</script>
